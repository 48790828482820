/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/naming-convention */

import React from 'react';
import cn from 'classnames';

import { Typography } from '../Typography/Typography';

import ButtonIcon from './Components/ButtonIcon';
import ButtonLoader from './Components/ButtonLoader';
import { ButtonProps } from './Button.types';

import styles from './Styles/Button.module.css';
import stylesVariant from './Styles/ButtonVariants.module.css';
import stylesSize from './Styles/ButtonSizes.module.css';
import { sizeVariantMap } from './Buttons.constants';

export const Button = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      size = 'large',
      color = 'primary',
      variant = 'contained',
      fullWidth,
      leftIcon,
      rightIcon,
      className,
      dataTestId,
      loading,
      Component = 'button',
      darkMode,
      ...restProps
    },
    ref,
  ) => {
    const classNames = cn(
      styles.button,
      stylesVariant[color],
      stylesVariant[variant],
      stylesSize[size],
      {
        [styles.loading]: loading,
        [styles.fullWidth]: fullWidth,
      },
      darkMode ? stylesVariant.darkMode : '',
      className,
    );

    return (
      <Component data-test-id={dataTestId} {...restProps} className={classNames} ref={ref}>
        {loading && <ButtonLoader className={styles.loader} />}
        {leftIcon && (
          <ButtonIcon className={styles.iconLeft} side="left">
            {leftIcon}
          </ButtonIcon>
        )}
        {children && (
          <Typography Component="span" className={styles.text} variant={sizeVariantMap[size]}>
            {children}
          </Typography>
        )}
        {rightIcon && (
          <ButtonIcon className={styles.iconRight} side="right">
            {rightIcon}
          </ButtonIcon>
        )}
      </Component>
    );
  },
);

Button.displayName = 'Button';

export default Button;
