'use client';

/* eslint-disable prettier/prettier */
import cn from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { ChevronDown } from '../Icons/Paths/ChevronDown';
import { ChevronTop } from '../Icons/Paths/ChevronTop';
import { Input } from '../Input/Input';

import DropdownOption from './Components/DropdownOption';
import { DropdownOptionProps } from './Components/DropdownOption.types';
import { DropdownProps } from './Dropdown.types';

import styles from './Styles/Default.module.css';
import { Typography } from '../Typography/Typography';

export const Dropdown: FC<DropdownProps> = ({
  Component = 'div',
  value,
  onChange,
  options,
  disabled,
  dataTestId,
  onBlur,
  onClick,
  onKeyDown,
  onFocus,
  label,
  hint,
  inputRef,
  placeholder,
  error,
  inputDropdown = false,
  inputChange,
  className,
  uncontrolled,
  placeholderOption,
  showLeftLabelIcon,
  isMenuPositionedAbove,
  ...restProps
}) => {
  const [stateValue, setStateValue] = useState<DropdownOptionProps | undefined>(value);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const highlightedOptionRef = useRef<HTMLLIElement>(null);

  const isValueIncludedInOptions = useCallback(
    (option: DropdownOptionProps | undefined) =>
      !!options.find(o =>
        Object.entries(o).every(([key, val]) => {
          return !!option && val === option[key as keyof DropdownOptionProps];
        }),
      ),
    [options],
  );

  // CONTROLLED Behavior (original)
  useEffect(() => {
    if (options.length === 0 && !uncontrolled) setStateValue(undefined);
    if (value && !isValueIncludedInOptions(value) && !uncontrolled) setStateValue(undefined);
    if (stateValue && !isValueIncludedInOptions(stateValue) && !uncontrolled)
      setStateValue(undefined);
    if (!stateValue && value && isValueIncludedInOptions(value) && !uncontrolled)
      setStateValue(value);
  }, [value, options, stateValue, uncontrolled, isValueIncludedInOptions]);

  // UNCONTROLLED Behavior
  useEffect(() => {
    if (uncontrolled) setStateValue(isValueIncludedInOptions(value) ? value : undefined);
  }, [value, uncontrolled, isValueIncludedInOptions]);

  const selectOption = useCallback(
    (option: DropdownOptionProps) => {
      if (onChange) {
        if (option !== stateValue) onChange(option);
      }
      setStateValue(option);
    },
    [onChange, stateValue],
  );

  const onClickHandler = useCallback(() => {
    onClick?.();
    if (!disabled) setIsOpen(nextState => !nextState);
  }, [onClick, disabled, setIsOpen]);

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0);
  }, [isOpen]);

  const isOptionSelection = (option: DropdownOptionProps) => {
    return option?.value === stateValue?.value;
  };

  const onBlurHandler = useCallback(() => {
    onBlur?.();
    setIsOpen(false);
  }, [onBlur, setIsOpen]);

  const classNames = cn(
    styles.container,
    isOpen && styles.isOpen,
    disabled && styles.disabled,
    className,
  );

  const valueClassNames = cn(disabled && 'disabled', styles.value);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputChange) {
      inputChange(e);
    }

    if (options.length > 0) {
      setIsOpen(true);
      setHighlightedIndex(0);
    } else {
      setIsOpen(false);
    }
  };

  const handleOnKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    e => {
      switch (e.code) {
        case 'Space':
          if (!isOpen) {
            setIsOpen(true);
          } else if (options.length > 0) {
            selectOption(options[highlightedIndex]);
            setIsOpen(false);
          }
          break;
        case 'ArrowUp': {
          e.preventDefault();
          const newValueUp = highlightedIndex === 0 ? options.length - 1 : highlightedIndex - 1;
          setHighlightedIndex(newValueUp);
          break;
        }
        case 'ArrowDown': {
          e.preventDefault();
          const nextValueDown = (highlightedIndex + 1) % options.length;
          setHighlightedIndex(nextValueDown);
          break;
        }
      }

      highlightedOptionRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'end',
      });
    },
    [highlightedIndex, options, selectOption],
  );

  const renderArrowIcon = () => {
    if (isOpen) {
      return isMenuPositionedAbove ? <ChevronDown /> : <ChevronTop />;
    }
    return isMenuPositionedAbove ? <ChevronTop /> : <ChevronDown />;
  };

  return (
    <Component
      className={classNames}
      onBlur={onBlurHandler}
      onClick={onClickHandler}
      ref={containerRef}
      tabIndex={0}
    >
      {inputDropdown ? (
        <Input
          aria-disabled={false}
          className={valueClassNames}
          disabled={disabled}
          error={error}
          fullWidth={true}
          hint={hint}
          label={label}
          onChange={onInputChange}
          {...(dataTestId && { dataTestId: `${dataTestId}-dropdown` })}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          ref={inputRef}
          rightIcon={renderArrowIcon()}
          value={value}
          {...restProps}
        />
      ) : (
        <Input
          aria-disabled
          className={valueClassNames}
          data-readonly
          disabled={disabled}
          error={error}
          {...(dataTestId && { dataTestId: `${dataTestId}-dropdown` })}
          fullWidth
          hint={hint}
          label={label}
          onChange={() => {
            setStateValue(options[highlightedIndex]);
          }}
          onKeyDown={e => {
            if (e.code === 'Enter' && onKeyDown) {
              onKeyDown(e as React.KeyboardEvent<HTMLInputElement>);
            } else {
              handleOnKeyDown(e);
            }
          }}
          onFocus={onFocus}
          placeholder={placeholder}
          leftLabelIcon={value?.leftContent && showLeftLabelIcon ? value.leftContent : null}
          ref={inputRef}
          rightIcon={renderArrowIcon()}
          value={stateValue?.label}
          name={dataTestId}
          {...restProps}
        />
      )}
      <ul
        className={`${styles.options} ${
          isOpen ? (isMenuPositionedAbove ? styles.menuTopPosition : styles.menuBottomPosition) : ''
        }`}
        data-test-id={dataTestId}
      >
        {placeholderOption && (
          <li className={`${styles.option} ${styles.placeholderOption}`}>
            <Typography variant="subheading3">{placeholderOption}</Typography>
          </li>
        )}
        {options.map((option, index) => (
          <li
            ref={highlightedIndex === index ? highlightedOptionRef : null}
            className={`${styles.option} ${isOptionSelection(option) ? styles.selected : ''} ${
              index === highlightedIndex ? styles.highlighted : ''
            }`}
            key={`${option.value}+${index}`}
            onMouseDown={e => {
              selectOption(option);
              e.stopPropagation();
              setIsOpen(false);
            }}
            onMouseEnter={() => setHighlightedIndex(index)}
          >
            <DropdownOption {...option} />
          </li>
        ))}
      </ul>
    </Component>
  );
};

Dropdown.displayName = 'Dropdown';
export type { DropdownOptionProps };
