'use client';

import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import { Vehicle } from '../../Entities/Vehicle/Vehicle.entity';

// eslint-disable-next-line @typescript-eslint/naming-convention
const isSSR = typeof window === 'undefined';
const storage = isSSR ? undefined : createJSONStorage<Vehicle | null>(() => sessionStorage);
export const currentVehicleAtom = atomWithStorage<Vehicle | null>('currentVehicle', null, storage);
